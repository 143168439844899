import React, { Component } from 'react';

import { FilePond, } from "react-filepond";
import "filepond/dist/filepond.min.css";
import wav from 'wav';
// import wav from './wav';
import './App.css';

import { Readable } from 'stream'




class App extends Component {
  state = {
    files: [],
    error: "",
  }
  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }

  render() {
    return (
      <div className="App">
        <FilePond
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          // allowMultiple={true}
          // maxFiles={3}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort) => {
              const fileReader = new FileReader();
              fileReader.onloadend = () => {
                var reader = new wav.Reader();
                reader.on('format', (format) => {

                  console.log('format', format);
                  //Veriy format
                  const kbps = parseInt(format.sampleRate / 1000);
                  if (format.channels === 1 && kbps === 48 && format.bitDepth === 24) {
                    //upload
                    load(format);
                  } else {
                    this.setState({ error: `System requires 48khz/24bit wav mono, file is ${kbps}Khz/${format.bitDepth}bit wav ${format.channels === 1 ? "mono" : "non-mono"}  ` })
                    error("File not acceptable");
                  }

                });


                const base64String = fileReader.result.replace('data:', '').replace(/^.+,/, '');
                const buffer = new Buffer(base64String, 'base64')

                const readable = new Readable()
                readable._read = () => { } // _read is required but you can noop it
                readable.push(buffer)
                readable.push(null)

                readable.pipe(reader) // consume the stream
              };
              fileReader.readAsDataURL(file);
            }
          }}
          oninit={() => this.handleInit()}
          onupdatefiles={fileItems => {
            // Set currently active file objects to this.state
            this.setState({
              error: "",
              files: fileItems.map(fileItem => fileItem.file)
            });
          }}
        />

        <div>{this.state.error}</div>

      </div>
    );
  }
}

export default App;
